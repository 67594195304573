import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const Barang = () => {

    const [barang, setBarang] = useState({});

    let { kode } = useParams();

    useEffect(() => {
        let myBarang = {
            kode: 1,
            nama: "Printer",
            harga: 3000000,
            modal: 2900000,
            jumlah: 2,                
        }

        setBarang(myBarang);
    }, [kode])

    return (
        <div className="text-left">
            <h2>Barang: {barang.nama}</h2>
            <hr />
        </div>
    )
}

export default Barang;