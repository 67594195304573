import React, { useState, useEffect } from "react";
import { Pagination, Form, Button } from "react-bootstrap";
import { Link, useOutletContext } from "react-router-dom";
import Input from "./form/Input";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";

const Barangs = () => {

	const pageChangeFunction = (p) => {
		if (p >= 1 && p <= totalPages) {
			setCurrentPage(p);
		}
	};

	const [barangs, setBarangs] = useState([])
	const [searchTerm, setSearchTerm] = useState(""); 
    const [fullList, setFullList] = useState([]);

    const { setAlertClassName } = useOutletContext();
    const { setAlertMessage } = useOutletContext();

	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);

	const totalPages = Math.ceil(barangs.length / itemsPerPage);

	const showPageItemsFunction = () => {
	
		const data = [];
		const numPage = 5;

		let leftSide = currentPage - numPage / 2 > 1;
		let rightSide = currentPage + numPage / 2 < totalPages;
		if (leftSide && rightSide) {
			leftSide = currentPage - numPage / 2 > 2;
			rightSide = currentPage + numPage / 2 < totalPages - 1;
		}
		data.push(
			<Pagination.First
				key="first"
				onClick={() => pageChangeFunction(1)}
			/>
		);
		data.push(
			<Pagination.Prev
				key="prev"
				onClick={() => pageChangeFunction(currentPage - 1)}
			/>
		);
		if (leftSide) {
			data.push(
				<Pagination.Ellipsis
					key="leftEllipsis"
					className="ellipsis"
					onClick={() => pageChangeFunction(currentPage - numPage)}
				/>
			);
		}
		const str = Math.max(1, Math.round(currentPage - numPage / 2));
		const end = Math.min(totalPages, Math.round(currentPage + numPage / 2));
		for (let i = str; i <= end; i++) {
			data.push(
				<Pagination.Item
					key={i}
					active={i === currentPage}
					onClick={() => pageChangeFunction(i)}
				>
					{i}
				</Pagination.Item>
			);
		}
		if (rightSide) {
			data.push(
				<Pagination.Ellipsis
					key="rightEllipsis"
					className="ellipsis"
					onClick={() => pageChangeFunction(currentPage + numPage)}
				/>
			);
		}
		data.push(
			<Pagination.Next
				key="next"
				onClick={() => pageChangeFunction(currentPage + 1)}
			/>
		);
		data.push(
			<Pagination.Last
				key="last"
				onClick={() => pageChangeFunction(totalPages)}
			/>
		);

		
		return data;
	};

	//perform a seacrh
    const performSeacrh = () => {
        console.log("Search:", searchTerm);

        const payload = `
        {
            search(kodeContains: "${searchTerm}") {
                kode
                nama
                harga
                modal
                jumlah
            }
        }`;

        const headers = new Headers();
        headers.append("Content-Type", "application/graphql");

        const requestOptions = {
            method: "POST",
            body: payload,
            headers: headers,
        }

        fetch(`${process.env.REACT_APP_BACKEND}/graph`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                let theList = Object.values(response.data.search);
                setBarangs(theList);
                if (theList.length > 0 ) {
                    setAlertClassName("d-none");
                    setAlertMessage("");
					if (currentPage !== 1) {
						setCurrentPage(1);
					}
                } else {
                    setAlertClassName("alert-danger");
                    setAlertMessage("Data not found");
                }
            })
            .catch(err => {console.log(err)})
    }

	const handleChange = (event) => {
        event.preventDefault();
        
        let value = event.target.value;
        setSearchTerm(value);

        console.log("handleChange:", value);
        console.log("searchTerm:", searchTerm);

        // if (value !== undefined) {
        //     if (value.length > 2) {
        //         performSeacrh();
        //     } else {
        //         setBarangs(fullList);
        //         if (fullList.length > 0 ) {
        //             setAlertClassName("d-none");
        //             setAlertMessage("");
        //         } else {
        //             setAlertClassName("alert-danger");
        //             setAlertMessage("Data not found");
        //         }
        //     }
        // }
    }

	const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const displayedItems = barangs.slice(startIndex, endIndex);

	useEffect(() => {
        const payload = `
        {
            list {
                kode
                nama
                harga
                modal
                jumlah
            }
        }`;
    
        const headers = new Headers();
        headers.append("Content-Type", "application/graphql");

        const requestOptions = {
            method: "POST",
            headers: headers,
            body: payload,
        }

        fetch(`${process.env.REACT_APP_BACKEND}/graph`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                let theList = Object.values(response.data.list);
                setBarangs(theList);
                setFullList(theList);
				if (theList.length > 0 ) {
                    setAlertClassName("d-none");
                    setAlertMessage("");
					if (currentPage !== 1) {
						setCurrentPage(1);
					}
                } else {
                    setAlertClassName("alert-danger");
                    setAlertMessage("Data not found");
                }
            })
            .catch(err => {console.log(err)})
    }, [])

	useEffect(() => {
        if (searchTerm !== undefined) {
            if (searchTerm.length > 2) {
                performSeacrh();
            } else {
                setBarangs(fullList);
                if (fullList.length > 0 ) {
                    setAlertClassName("d-none");
                    setAlertMessage("");
					if (currentPage !== 1) {
						setCurrentPage(1);
					}
                } else {
                    setAlertClassName("alert-danger");
                    setAlertMessage("Data not found");
                }
            }
        }
    }, [searchTerm])
	

	return (
		<div>
			<h2>Daftar barang</h2>
			<hr />

			<form onSubmit={handleChange}>
                <Input 
                    title={"Search"}
                    type={"search"}
                    name={"search"}
                    className={"form-control"}
                    value={searchTerm}
                    onChange={handleChange} />
            </form>

			{barangs ? 
			(
				<>
				<table className="table table-striped table-hover">
					<thead>
						<tr>
							<th>Kode</th>
							<th>Nama</th>
							<th className="text-center">Harga</th>
							<th className="text-center">Modal</th>
							<th className="text-center">Jumlah</th>
						</tr>
					</thead>
					<tbody>
						{displayedItems.map((m) => (
							<tr key={m.kode}>
								<td>
									<Link to={`/barangs/${m.kode}`}>
										{m.kode}
									</Link>
								</td>
								<td>{m.nama}</td>
								<td className="text-center">{m.harga.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</td>
								<td className="text-center">{m.modal.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</td>
								<td className="text-center">{m.jumlah.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</td>
							</tr>
						))}
					</tbody>
				</table>

				<div className="app-container">
					<Pagination className="pagination">
						{showPageItemsFunction()}
					</Pagination>
				</div>
				</>
			) : (
                <p>Tidak ada barang!</p>
            )}
		</div>
	);
}
export default Barangs;
