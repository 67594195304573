import Logo from './../images/logo.jpg'
import './../App.css'

const Home = () => {

    return (
        <>
        <div className="text-center">
            <h2>Selamat datang di Toko komputer App</h2>
            <hr />
            <img src={Logo} alt="logo" className="image-container"></img>
        </div>
        </>
    )
}

export default Home;